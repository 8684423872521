'use client';

import * as React from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import CarouselWidget from 'components/ui/CarouselWidget';
import Block from '@/components/ui/Block';
import Animations from '@/components/ui/Animations';
import Breakout from '@/components/ui/Block/Breakout';
import SearchField from '@/components/ui/SearchField';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

interface IProps {
  imageUrl?: string;
}

const Intro: React.FC<IProps> = ({ imageUrl }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Block
        theme="dark"
        className={styles['intro']}
      >
        <div className={styles['intro__icon']}>
          <Icon
            width={144}
            height={144}
            kind="statistics"
          />
        </div>
        <Breakout width="narrow">
          <h1>{t('components.intro.title')}</h1>
          <SearchField />
        </Breakout>
      </Block>
      <Block className={styles['intro__decorations']}>
        <div className={styles['intro__decorations__inner']}>
          <CarouselWidget
            items={[
              {
                label: t('components.intro.marketReviews'),
                imgSrc: '/tmp/illustration01.svg',
              },
              {
                label: t('components.intro.marketingLists'),
                imgSrc: '/tmp/illustration02.svg',
              },
              {
                label: t('components.intro.salesAgent'),
                imgSrc: '/tmp/illustration03.svg',
              },
              {
                label: t('components.intro.certificates'),
                imgSrc: '/tmp/illustration04.svg',
              },
              {
                label: t('components.intro.dataIntegration'),
                imgSrc: '/tmp/illustration05.svg',
              },
              {
                label: t('components.intro.debtRegister'),
                imgSrc: '/tmp/illustration06.svg',
              },
              {
                label: t('components.intro.creditInformation'),
                imgSrc: '/tmp/illustration07.svg',
              },
            ]}
            className={styles['intro__decorations__carousel']}
          />
          <Animations
            animation="animation03"
            className={styles['intro__decorations__animation']}
          />
          {imageUrl && (
            <div className={styles['intro__decorations__image']}>
              <Image
                src={imageUrl}
                alt="Infopank"
                fill={true}
                sizes="100vw"
                priority={true}
              />
            </div>
          )}
        </div>
      </Block>
    </React.Fragment>
  );
};

Intro.displayName = 'Intro';

export default Intro;
