'use client';

import * as React from 'react';
import Image from 'next/image';
import { useIntersect } from '@/components/hooks/useIntersect';
import Animations from '@/components/ui/Animations';
import styles from './styles.module.scss';

function clamp(number: number, min: number, max: number) {
  return Math.max(min, Math.min(number, max));
}

const FlexImage: React.FunctionComponent<{
  imageUrl: string;
  index: number;
  percentage: number;
}> = (props) => {
  return (
    <div
      className={styles['slideshow__slide__fleximage']}
      style={{
        zIndex: props.index,
      }}
    >
      <div
        className={styles['slideshow__slide__clipper']}
        style={{
          height: `${props.percentage}%`,
        }}
      >
        <div className={styles['slideshow__slide__holder']}>
          <Image
            src={props.imageUrl}
            alt="" // TODO
            fill={true}
            sizes="(min-width: 1px) 50vw, 100vw"
          />
        </div>
      </div>
    </div>
  );
};

const Content: React.FunctionComponent<{
  content: React.ReactNode;
  imageUrl: string;
}> = (props) => {
  return (
    <div className={styles['slideshow__slide']}>
      <div className={styles['slideshow__slide__image']}>
        <Image
          src={props.imageUrl}
          alt="" // TODO
          fill={true}
          sizes="(min-width: 1px) 50vw, 100vw"
        />
      </div>
      <div className={styles['slideshow__slide__content']}>{props.content}</div>
    </div>
  );
};

const Slideshow: React.FunctionComponent<{
  slides: {
    content: React.ReactNode;
    imageUrl: string;
  }[];
}> = (props) => {
  const [percentage, setPercentage] = React.useState(0);
  const elem = useIntersect({
    root: null,
    rootMargin: '0px',
    threshold: Array.from(Array(100).keys(), (i) => i / 100),
  });

  React.useEffect(() => {
    const onScroll = () => {
      if (elem.entry) {
        const top = typeof window !== 'undefined' ? document.documentElement.scrollTop : 0;
        const off = elem.node.current?.offsetTop || 0;
        const max = elem.entry.boundingClientRect.height;
        const st = top - off + 72; // Compensate with sticky header height
        const min = st + window.innerHeight - 104; // Compensate with sticky header + padding height
        const per = (min * 100) / max;

        setPercentage(per);
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll);
  }, [elem, setPercentage]);

  return (
    <div className={styles['slideshow']}>
      <div className={styles['slideshow__animation']}>
        <Animations animation="animation01" />
      </div>
      <div
        className={styles['slideshow__wrapper']}
        ref={elem.node}
      >
        <div className={styles['slideshow__images']}>
          <div className={styles['slideshow__images__holder']}>
            {props.slides.map((slide, index) => {
              const len = props.slides.length;
              const segment = 100 / len;
              const min = index * segment;
              const max = min + segment;
              const normalized = clamp(((percentage - min) * 100) / (max - min), 0, 100);

              return (
                <FlexImage
                  key={index}
                  imageUrl={slide.imageUrl}
                  index={index + 1}
                  percentage={index === 0 ? 100 : normalized}
                />
              );
            })}
          </div>
        </div>
        <div className={styles['slideshow__slides']}>
          {props.slides.map((slide, index) => {
            return (
              <Content
                key={index}
                imageUrl={slide.imageUrl}
                content={slide.content}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Slideshow.displayName = 'Slideshow';

export default Slideshow;
