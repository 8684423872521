'use client';

import * as React from 'react';

export function useResize(callback: () => void): void {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  React.useEffect(() => {
    const onResize = () => {
      if (typeof callbackRef.current === 'function') {
        callbackRef.current();
      }
    };

    requestAnimationFrame(onResize);

    window.addEventListener('resize', onResize, { passive: true });

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
}
