'use client';

import * as React from 'react';
import Image from 'next/image';
import { useElementSize } from '@/components/hooks/useElementSize';
import styles from './styles.module.scss';

export interface ISupportersProps {
  data: {
    name: string;
    url: string;
    imageUrl: string;
  }[];
}

export function animate(
  element: HTMLElement,
  keyframes: Keyframe[],
  options: KeyframeAnimationOptions,
  signal: AbortSignal,
): Promise<void> {
  return new Promise((resolve) => {
    const animation = element.animate(keyframes, options);

    const onAbort = () => {
      animation.cancel();
    };

    const onFinish = () => {
      signal.removeEventListener('abort', onAbort);
      animation.removeEventListener('finish', onFinish);
      resolve();
    };

    signal.addEventListener('abort', onAbort);
    animation.addEventListener('finish', onFinish);
  });
}

const RenderList: React.FunctionComponent<ISupportersProps> = (props) => {
  return (
    <ul className={styles['supporters__ticker__list']}>
      {props.data.map((supporter) => {
        return (
          <li key={supporter.name}>
            <a
              href={supporter.url}
              title={supporter.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={supporter.imageUrl}
                alt={supporter.name}
                fill={true}
                sizes="(min-width: 1px) 100vw, 100vw" // NEXTJS reports fales positive if just 100vw
              />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const Supporters: React.FunctionComponent<ISupportersProps> = (props) => {
  const track1 = React.useRef<HTMLDivElement>(null);
  const track2 = React.useRef<HTMLDivElement>(null);

  const options = React.useMemo<KeyframeAnimationOptions>(
    () => ({
      duration: 50000,
      easing: 'linear',
      delay: 0,
      iterations: 1,
      fill: 'forwards',
      direction: 'normal',
    }),
    [],
  );

  const { width: trackWidth } = useElementSize(track1);

  React.useEffect(() => {
    if (!trackWidth || !track1.current || !track2.current) {
      return;
    }

    const width = trackWidth;
    const track1El = track1.current;
    const track2El = track2.current;
    const controller = new AbortController();

    async function toggle(): Promise<void> {
      const zeroToMinusOne = [{ transform: 'translateX(0px)' }, { transform: `translateX(${-1 * width}px)` }];

      const oneToZero = [{ transform: `translateX(${width}px)` }, { transform: `translateX(${0}px)` }];

      const minusOneToMinusTwo = [{ transform: `translateX(${-1 * width}px)` }, { transform: `translateX(${-2 * width}px)` }];

      const promise1 = animate(track1El, zeroToMinusOne, options, controller.signal).then(() =>
        animate(track1El, oneToZero, options, controller.signal),
      );

      const promise2 = animate(track2El, zeroToMinusOne, options, controller.signal).then(() =>
        animate(track2El, minusOneToMinusTwo, options, controller.signal),
      );

      return Promise.all([promise1, promise2]).then(() => toggle());
    }

    void toggle();

    return () => {
      controller.abort();
    };
  }, [trackWidth, options]);

  if (props.data.length === 0) {
    return null;
  }

  return (
    <div className={styles['supporters']}>
      <div className={styles['supporters__ticker']}>
        <div
          ref={track1}
          className={styles['supporters__ticker__group']}
        >
          <RenderList data={props.data} />
        </div>
        <div
          ref={track2}
          className={styles['supporters__ticker__group']}
        >
          <RenderList data={props.data} />
        </div>
      </div>
    </div>
  );
};

Supporters.displayName = 'Ticker';

export default Supporters;
