'use client';

import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import classNames from 'classnames';
import styles from './styles.module.scss';

const settings: Settings = {
  dots: false,
  arrows: false,
  infinite: false,
  initialSlide: 2,
  autoplay: true,
  autoplaySpeed: 2000,
  focusOnSelect: true,
  easing: 'cubic-bezier(.44,0,.38,1.37)',
  speed: 1,
  useCSS: false,
  useTransform: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: false,
  centerMode: true,
  centerPadding: '0',
  swipe: false,
  draggable: false,
  fade: false,
  pauseOnHover: true,
  swipeToSlide: false,
  touchMove: false,
  waitForAnimate: false,
};

const CarouselWidget: React.FunctionComponent<{ items: { label: string; imgSrc: string }[]; className?: string }> = (props) => {
  return (
    <div className={classNames(styles['carousel-widget'], props.className)}>
      <Slider {...settings}>
        {[...props.items, ...props.items, ...props.items, ...props.items, ...props.items].map((item, i) => (
          <div
            key={i}
            className={styles['carousel-widget__item']}
          >
            <div className={styles['carousel-widget__item__label']}>{item.label}</div>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              width={80}
              height={80}
              src={item.imgSrc}
              alt={item.label}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

CarouselWidget.displayName = 'CarouselWidget';

export default CarouselWidget;
