'use client';

import * as React from 'react';
import { useResize } from '@/components/hooks/useResize';

export function useElementSize(element: React.RefObject<HTMLDivElement>): {
  width: number | undefined;
  height: number | undefined;
} {
  const [width, setWidth] = React.useState<number>();
  const [height, setHeight] = React.useState<number>();

  useResize(() => {
    setWidth((previousWidth) => {
      const nextWidth = element.current?.getBoundingClientRect().width;
      return previousWidth !== nextWidth ? nextWidth : previousWidth;
    });
    setHeight((previousHeight) => {
      const nextHeight = element.current?.getBoundingClientRect().height;
      return previousHeight !== nextHeight ? nextHeight : previousHeight;
    });
  });
  return {
    width,
    height,
  };
}
