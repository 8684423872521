'use client';

import * as React from 'react';
import styles from './styles.module.scss';

export interface IStatsProps {
  items: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[];
}

const Stats: React.FunctionComponent<IStatsProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }

  return (
    <ul className={styles['stats']}>
      {props.items.map((item, itemIndex) => (
        <li key={itemIndex}>
          <div className={styles['stats__value']}>{item.value}</div>
          <div className={styles['stats__label']}>{item.label}</div>
        </li>
      ))}
    </ul>
  );
};

Stats.displayName = 'Stats';

export default Stats;
